<template>
  <div class="mt-5" id="vidaSaludable">
    <div class="container" ref="como-elegir-los-hidratos-de-carbono">
      <Articulo
        :title="$t('articulos.2.a.titulo')"
        :text="$t('articulos.2.a.texto')"
        fuenteImg=""
        fuente=""
        img=""
        :productos="[
          'sustenta-neutro',
          'sustenta-vainilla',
          'sustenta-fibras',
          'sustenta-semielemental',
          'poli-carb',
          'dextrosa',
        ]"
      />
    </div>
    <div class="container" ref="hidratacion-deporte-y-salud">
      <Articulo
        :title="$t('articulos.2.b.titulo')"
        :text="$t('articulos.2.b.texto')"
        fuenteImg=""
        :fuente="$t('articulos.2.b.fuente')"
        img=""
      />
    </div>
    <div class="container" ref="cuanta-azucar-es-saludable">
      <Articulo
        :title="$t('articulos.2.c.titulo')"
        :text="$t('articulos.2.c.texto')"
        fuenteImg=""
        fuente=""
        img=""
        :productos="[
          'sustenta-db-neutro',
          'sustenta-db-vainilla',
        ]"
        :customSettings="{itemsToShow: 2, centerMode: false,  pagination: 'fraction', trimWhiteSpace: false, itemsToSlide: 0}"
        :shrinkRecomendados="true"
      />
    </div>
    <div class="container" ref="snacks-previo-entrenamiento">
      <Articulo
        :title="$t('articulos.2.d.titulo')"
        :text="$t('articulos.2.d.texto')"
        fuenteImg=""
        fuente=""
        img=""
        :productos="[
          'dextrosa',
        ]"
        :customSettings="{itemsToShow: 1, centerMode: true,  pagination: 'fraction', trimWhiteSpace: false, itemsToSlide: 1}"
        :shrinkRecomendados="true"
      />
    </div>
  </div>
</template>

<script>
import Articulo from "@/components/Articulo.vue";
export default {
  name: "VidaSaludable",
  components: {
    Articulo,
  },
  data() {
    return {
      hash: this.$route.hash,
    };
  },
  mounted() {
    this.$nextTick(function () {
      if (this.hash) {
        const refName = this.hash.replace("#", "");
        this.scrollToAnchorPoint(refName);
      }
    });
  },
  methods: {
    getImage(fileName) {
      return require("../../assets/images/carousel/" +
        this.$i18n.locale +
        "/" +
        fileName);
    },
    scrollToAnchorPoint(refName) {
      const el = this.$refs[refName];
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>