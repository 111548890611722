var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5",attrs:{"id":"vidaSaludable"}},[_c('div',{ref:"como-elegir-los-hidratos-de-carbono",staticClass:"container"},[_c('Articulo',{attrs:{"title":_vm.$t('articulos.2.a.titulo'),"text":_vm.$t('articulos.2.a.texto'),"fuenteImg":"","fuente":"","img":"","productos":[
        'sustenta-neutro',
        'sustenta-vainilla',
        'sustenta-fibras',
        'sustenta-semielemental',
        'poli-carb',
        'dextrosa' ]}})],1),_c('div',{ref:"hidratacion-deporte-y-salud",staticClass:"container"},[_c('Articulo',{attrs:{"title":_vm.$t('articulos.2.b.titulo'),"text":_vm.$t('articulos.2.b.texto'),"fuenteImg":"","fuente":_vm.$t('articulos.2.b.fuente'),"img":""}})],1),_c('div',{ref:"cuanta-azucar-es-saludable",staticClass:"container"},[_c('Articulo',{attrs:{"title":_vm.$t('articulos.2.c.titulo'),"text":_vm.$t('articulos.2.c.texto'),"fuenteImg":"","fuente":"","img":"","productos":[
        'sustenta-db-neutro',
        'sustenta-db-vainilla' ],"customSettings":{itemsToShow: 2, centerMode: false,  pagination: 'fraction', trimWhiteSpace: false, itemsToSlide: 0},"shrinkRecomendados":true}})],1),_c('div',{ref:"snacks-previo-entrenamiento",staticClass:"container"},[_c('Articulo',{attrs:{"title":_vm.$t('articulos.2.d.titulo'),"text":_vm.$t('articulos.2.d.texto'),"fuenteImg":"","fuente":"","img":"","productos":[
        'dextrosa' ],"customSettings":{itemsToShow: 1, centerMode: true,  pagination: 'fraction', trimWhiteSpace: false, itemsToSlide: 1},"shrinkRecomendados":true}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }